<template>
  <router-view />
</template>

<script>
import "@/assets/js/ak-golf/common";

export default {
  name: "AkGolfLayout",
};
</script>

<style scoped>
@import "~@/assets/css/ak-golf/content.css";
@import "~@/assets/css/ak-golf/common.css";
@import "~@/assets/css/ak-golf/normalize.css";
</style>
